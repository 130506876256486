<template>
  <v-container
    id="wizard"
    tag="section"
  >
    <validation-observer v-slot="{ valid }">
      <base-login-material-wizard
        ref="baseLoginMat"
        v-model="tab"
        :available-steps="availableSteps"
        :items="tabs"
        :disabledBtnLogar="disabledBtnLogar"
        :loading="loading"
        class="mx-auto"
        @click:next="next(valid)"
      >
        <v-tab-item class="pb-1">
          <form @submit.prevent="next(valid)">
            <v-row v-if="selectedCompany.logo"
              class="mt-n5"
            >
              <v-col
                cols="12"
                md="12"
                justify="center"
                align="center"
              >
                <v-img
                  :src="selectedCompany.logo"
                  width="180"
                />
              </v-col>
            </v-row>
            <v-row
              no-gutters
              class="mb-0 pb-0"
            >
              <v-col
                cols="12"
                md="12"
              >
                <v-responsive
                  max-width="500"
                >
                  <div class="text-center display-1 font-weight-medium mb-6">
                    <span class="grey--text text--darken-2" v-if="!selectedCompany.id">
                      Bem-vindo, selecione a empresa
                    </span>
                  </div>
                  <select-company
                    ref="refSelectEmp"
                    :type="'outlined'"
                    @companySelected="companySelected"
                  />
                </v-responsive>
              </v-col>
            </v-row>
          </form>
        </v-tab-item>
        <v-tab-item class="pb-1">
          <form @submit.prevent="next(valid)">
            <v-row
              class="mx-auto"
              justify="space-around"
            >
              <v-col
                cols="12"
                md="11"
              >
                <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                  name="login"
                >
                  <v-text-field
                    v-model="login"
                    :error-messages="errors"
                    color="primary"
                    label="Login*"
                    prepend-icon="mdi-account-outline"
                    validate-on-blur
                  />
                </validation-provider>

                <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                  name="password"
                >
                  <v-text-field
                    v-model="password"
                    :error-messages="errors"
                    :append-icon="iconEye ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="iconEye ? 'text' : 'password'"
                    color="primary"
                    label="Senha*"
                    prepend-icon="mdi-lock-outline"
                    @keyup.enter="next(valid)"
                    @click:append="iconEye = !iconEye"
                    validate-on-blur
                  />
                </validation-provider>
                
                <router-link 
                  to="/esqueceu-senha"
                  v-if="authType === 'database'"
                ><small>Esqueceu sua senha?</small></router-link>
              </v-col>
            </v-row>
          </form>
        </v-tab-item>
      </base-login-material-wizard>
    </validation-observer>
  </v-container>
</template>

<script>
import authService from '@/services/AuthService';

export default {

  name: 'LoginWizard',

  components: {
    BaseLoginMaterialWizard: () => import('@/components/login/base/BaseLoginMaterialWizard.vue'),
    SelectCompany: () => import('@/components/general/SelectCompany.vue'),
  },

  data() {
    return {
      iconEye: false,
      tab: 0,
      tabs: ['Empresa', 'Login'],
      login: '',
      password: '',
      loading: false,
      authType: '',
      selectedCompany: {
        id: null,
        name: null,
        logo: null
      }
    };
  },

  computed: {

    availableSteps() {
      const steps = [0];
      if (this.selectedCompany.id) {
        steps.push(1);
      }
      return steps;
    },

    disabledBtnLogar() {
      return !(this.login && this.password && this.selectedCompany.id);
    }
  },

  methods: {

    companySelected(event) {
      this.selectedCompany = event;
      this.$store.dispatch('setSelectedCompany', this.selectedCompany);
    },

    async next (valid) {  
      
      if (!valid) return

      if (this.tab === 0) {
        const selectedCompany = this.selectedCompany.id;
        if (!selectedCompany) {
          return
        }
        this.$refs.baseLoginMat.activateSecondTab();
      }

      if (this.tab === 1) {
        this.logged = await this.authentication();
        if (!this.logged) {
          return;
        }

        this.$router.push({
          name: 'Validações'
        });
      } else {
        this.tab++;
      }
    },

    async authentication () {
      
      let logged;
      this.loading = true;
      
      try {

        await this.$store.dispatch('login', {
          login: this.login, 
          password: this.password,
          companyId: this.selectedCompany.id
        });
        logged = true;
      } catch (e) {
        this.$toast.error('Login ou senha inválidos.', '', { position: 'topRight' });
      } finally {
        this.loading = false;
      }

      return logged;
    }
  },

  created() {
    authService.getTipoAutenticacao().then(tipoAutenticacao => {
      this.authType = tipoAutenticacao;
    });
  }
}
</script>

<style lang="sass">
  .v-card.v-card.v-card--account
    border-color: currentColor
    border-width: 4px

    .v-icon
      color: inherit

  .v-card--account,
  .v-card--account:before
    border-radius: 50%

</style>